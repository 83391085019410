.logo {
  width: 200px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: auto;
}

.logoSmall {
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 50px;
}

.sidebar {
  height: 99vh;
}

.container {
  display: flex;
  height: 100vh;
}

.wizard {
  flex: 1;
  width: 40%;
}

.wizardPage {
  height: calc(100vh - 165px);
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
}

.header {
  height: 80px;
  background-color: rgb(249, 249, 249, 0.7);
  border-bottom: 1px solid #efefef;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.headerTitle {
  font-weight: bold;
  font-size: 24px;
}

.profile {
  position: absolute;
  top: 40px;
  transform: translateY(-50%);
  right: 10px;
}

.footer {
  height: 80px;
  background-color: rgb(249, 249, 249, 0.7);
  border-top: 1px solid #efefef;
  text-align: center;
}

.footer button {
  top: 30%
}

.accordionMail {
  margin-top: 10px;
}

.listLabel {
  font-weight: bold !important;
  width: 220px;
  border: 0
}

.elaborazioneLoader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: green;
  box-shadow: 0 0 0 0 #0004;
  animation: l1 1s infinite;
  float: left;
  margin-right: 10px;
}

.datepickerRid input {
  padding: 9px 14px;
}

@keyframes l1 {
  100% {
    box-shadow: 0 0 0 1cap #0000
  }
}

.elaborazioneMsg {
  cursor: pointer;
  font-weight: bold;
  color: green;
}

.css-1ynyhby {
  margin: 0px !important;
}

.css-w24xun-MuiPaper-root {
  overflow: unset !important;
}

.hover-icon {
  color: lightgray;
}

.hover-icon:hover {
  color: black;
}

.hidden-title {
  display: none;
}

/* Classe usata per aggiungere un peso diverso al testo, al passaggio del mouse */
.button-style:hover {
  font-weight: 600;
}

/* Classe usata per evidenziare la voce di menu corrente */
.selected-step {
  background-color: rgba(211, 211, 211, 0.2);
  border-bottom: 1px solid rgba(211, 211, 211, 1);
  border-top: 1px solid rgba(211, 211, 211, 1);
}

.css-uqq6zz-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: lighter;
}

/* 404 PAGE STYLE */

.main {
  margin-top: 5%;
}

.st0 {
  fill: #fff
}

.st2 {
  fill: #5d89af
}

.st3 {
  fill: #709abf
}

.st4,
.st6 {
  fill: #fff;
  stroke: #b3dcdf;
  stroke-miterlimit: 10
}

.st6 {
  stroke: #5d89af;
  stroke-width: 2
}

.st7,
.st8,
.st9 {
  stroke: #709abf;
  stroke-miterlimit: 10
}

.st7 {
  stroke-width: 5;
  stroke-linecap: round;
  fill: none
}

.st8,
.st9 {
  fill: #fff
}

.st9 {
  fill: none
}

/* .st10 {} */

#cloud1 {
  animation: cloud003 15s linear infinite;
}

#cloud2 {
  animation: cloud002 25s linear infinite;
}

#cloud3 {
  animation: cloud003 20s linear infinite;
}

#cloud4 {
  animation: float 4s linear infinite;
}

#cloud5 {
  animation: float 8s linear infinite;
}

#cloud7 {
  animation: float 5s linear infinite;
}

#tracks {
  animation: slide 650ms linear infinite;
}

#bumps {
  animation: land 10000ms linear infinite;
}

@keyframes jig {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
  }
}

#car-layers {
  animation: jig 0.35s linear infinite;
}

@keyframes land {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(1000px);
  }
}


@keyframes slide {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(100px);
  }
}

/* @keyframes cloudFloat {
    0% { transform: translateX(0) translateY(3px); }
    100% { transform: translateX(1000px) translateY(0); }
  } */

@keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }

  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }

  50% {
    transform: translateY(8px) translateX(5px);
  }

  100% {
    transform: translateY(0px) translateX(0);
  }
}

#bracefront,
#braceback {
  animation: braces 1s linear infinite;
}

@keyframes braces {
  0% {
    transform: translateX(-2px);
  }

  25% {
    transform: translateX(3px);
  }

  50% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(3px);
  }

  100% {
    transform: translateX(-2px);
  }
}